import React, { useState, useEffect, useMemo } from 'react';
import '../admin/TourEdit.css';
import {
  Table,
  TextInput,
  Button,
  Heading,
  Pane,
  toaster,
  FilePicker,
  SelectMenu,
  Textarea,
  TagInput,
} from 'evergreen-ui';

function TourEdit() {
  const [tours, setTours] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editTourId, setEditTourId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSafariType, setSelectedSafariType] = useState(null);

  const [formData, setFormData] = useState({
    country: '',
    safari_type: '',
    destination_title: '',
    destination_name: '',
    duration: '',
    price: '',
    image_preview: null,
    info_overview: '',
    info_highlight: '',
    departure: '',
    included: [], // Initialize as an empty array
    excluded: [], // Initialize as an empty array
    travel_plan_overview: '',
    travel_plan_day_title_1: '',
    travel_plan_day_description_1: '',
    travel_plan_day_title_2: '',
    travel_plan_day_description_2: '',
    travel_plan_day_title_3: '',
    travel_plan_day_description_3: '',
    travel_plan_day_title_4: '',
    travel_plan_day_description_4: '',
    travel_plan_day_title_5: '',
    travel_plan_day_description_5: '',
    travel_plan_day_title_6: '',
    travel_plan_day_description_6: '',
    travel_plan_day_title_7: '',
    travel_plan_day_description_7: '',
    image_gallery_1: null,
    image_gallery_2: null,
    image_gallery_3: null,
    image_gallery_4: null,
    image_gallery_5: null,
    image_gallery_6: null,
    image_gallery_7: null,
    image_gallery_8: null,
    image_gallery_9: null,
  });

  const fetchTours = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours`);
      const data = await response.json();
      setTours(data);
    } catch (error) {
      console.error('Error fetching tours:', error);
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    setEditTourId(tour.id);
    setSelectedCountry(tour.country);
    setSelectedSafariType(tour.safari_type);
    setFormData({
      ...tour, // Assign tour fields directly to formData
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTagInputChange = (name, values) => {
    setFormData({ ...formData, [name]: values });
  };

  const handleImageChange = (files, name) => {
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleUpdate = async () => {
    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours/${editTourId}`, {
        method: 'PUT',
        body: formDataToSubmit,
      });

      if (!response.ok) {
        throw new Error('Error updating tour');
      }

      toaster.success('Tour updated successfully!');
      setEditTourId(null);
      setFormData({
        country: '',
        safari_type: '',
        destination_title: '',
        destination_name: '',
        duration: '',
        price: '',
        image_preview: null,
        info_overview: '',
        info_highlight: '',
        departure: '',
        included: [],
        excluded: [],
        travel_plan_overview: '',
        travel_plan_day_title_1: '',
        travel_plan_day_description_1: '',
        travel_plan_day_title_2: '',
        travel_plan_day_description_2: '',
        travel_plan_day_title_3: '',
        travel_plan_day_description_3: '',
        travel_plan_day_title_4: '',
        travel_plan_day_description_4: '',
        travel_plan_day_title_5: '',
        travel_plan_day_description_5: '',
        travel_plan_day_title_6: '',
        travel_plan_day_description_6: '',
        travel_plan_day_title_7: '',
        travel_plan_day_description_7: '',
        image_gallery_1: null,
        image_gallery_2: null,
        image_gallery_3: null,
        image_gallery_4: null,
        image_gallery_5: null,
        image_gallery_6: null,
        image_gallery_7: null,
        image_gallery_8: null,
        image_gallery_9: null,
      });
      fetchTours();
    } catch (error) {
      console.error('Error updating tour:', error);
      toaster.danger('Failed to update tour. Please try again.');
    }
  };

  const handleDelete = async (tourId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours/${tourId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error deleting tour');
      }

      toaster.success('Tour deleted successfully!');
      fetchTours();
    } catch (error) {
      console.error('Error deleting tour:', error);
      toaster.danger('Failed to delete tour. Please try again.');
    }
  };

  const filteredTours = useMemo(() => {
    return tours.filter(tour =>
      tour.destination_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, tours]);

  return (
    <div className='container_edit_tour'>
      <Heading size={600} marginBottom={16}>Manage Tours</Heading>
      <TextInput
        placeholder="Search by Destination Title"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        marginBottom={16}
      />
      <Table className="styled-table">
        <thead>
          <tr>
            <th>Destination Title</th>
            <th>Destination Name</th>
            <th>Duration</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTours.map(tour => (
            <tr key={tour.id}>
              <td>{tour.destination_title}</td>
              <td>{tour.destination_name}</td>
              <td>{tour.duration}</td>
              <td>{tour.price}</td>
              <td>
                <Button appearance='primary' onClick={() => handleEdit(tour)}>Edit</Button>
                <Button appearance='primary' icon="trash" intent="danger" onClick={() => handleDelete(tour.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {editTourId && (
        <Pane marginTop={16} padding={16} background="tint1">
          <Heading size={500} marginBottom={16}>Edit Tour</Heading>

          {/* Country Selection */}
          <SelectMenu
            title="Select Country"
            options={['Kenya', 'Uganda', 'Tanzania'].map((label) => ({ label, value: label }))}
            selected={selectedCountry}
            onSelect={(item) => {
              setSelectedCountry(item.value);
              setFormData({ ...formData, country: item.value });
            }}
          >
            <Button>{selectedCountry || 'Select a country'}</Button>
          </SelectMenu>

          {/* Safari Type Selection */}
          <SelectMenu
            title="Select Safari Type"
            options={['Excursions', 'Wildlife Safari', 'Cultural Safari', 'Beach Safari', 'Luxury Safari', 'Family Safari'].map((label) => ({ label, value: label }))}
            selected={selectedSafariType}
            onSelect={(item) => {
              setSelectedSafariType(item.value);
              setFormData({ ...formData, safari_type: item.value });
            }}
          >
            <Button>{selectedSafariType || 'Select a safari type'}</Button>
          </SelectMenu>

          {/* Other Fields */}
          <TextInput
            label="Destination Title"
            name="destination_title"
            value={formData.destination_title}
            onChange={handleChange}
            marginBottom={8}
          />
          <TextInput
            label="Destination Name"
            name="destination_name"
            value={formData.destination_name}
            onChange={handleChange}
            marginBottom={8}
          />
          <TextInput
            label="Duration"
            name="duration"
            type="number"
            value={formData.duration}
            onChange={handleChange}
            marginBottom={8}
          />
          <TextInput
            label="Price"
            name="price"
            type="number"
            value={formData.price}
            onChange={handleChange}
            marginBottom={8}
          />

          {/* Image File Picker */}
          <FilePicker
            label="Image Preview"
            name="image_preview"
            onChange={(file) => handleImageChange(file, 'image_preview')}
            marginBottom={8}
            accept="image/*"
          />

          {/* Overview & Highlights */}
          <Textarea
            label="Overview"
            name="info_overview"
            value={formData.info_overview}
            onChange={handleChange}
            marginBottom={8}
          />
          <Textarea
            label="Highlights"
            name="info_highlight"
            value={formData.info_highlight}
            onChange={handleChange}
            marginBottom={8}
          />

<TextInput
              label="Departure Location"
              name="departure"
              placeholder="Enter the departure location (e.g. Nairobi)"
              value={formData.departure}
              onChange={handleChange}
            />

          {/* Tag Inputs for Included & Excluded */}
          <TagInput
  label="Included"
  inputProps={{ placeholder: 'Add items...' }}
  values={Array.isArray(formData.included) ? formData.included : []}  // Ensure values is an array
  onChange={(values) => handleTagInputChange('included', values)}
  marginBottom={8}
/>

<TagInput
  label="Excluded"
  inputProps={{ placeholder: 'Add items...' }}
  values={Array.isArray(formData.excluded) ? formData.excluded : []}  // Ensure values is an array
  onChange={(values) => handleTagInputChange('excluded', values)}
  marginBottom={8}
/>

<Textarea
              label="Travel Plan Overview"
              name="travel_plan_overview"
              placeholder="Enter an overview of the travel plan (e.g. Daily itinerary breakdown)"
              value={formData.travel_plan_overview}
              onChange={handleChange}
              height={120}
            />

{[1, 2, 3, 4, 5, 6, 7].map((num) => (
              <React.Fragment key={num}>
                <TextInput
                  label={`Day ${num} Title`}
                  name={`travel_plan_day_title_${num}`}
                  placeholder={`Enter title for Day ${num} (e.g. Arrival and Welcome Briefing)`}
                  value={formData[`travel_plan_day_title_${num}`]}
                  onChange={handleChange}
                />
                <Textarea
                  label={`Day ${num} Description`}
                  name={`travel_plan_day_description_${num}`}
                  placeholder={`Enter details for Day ${num} (e.g. Arrival at Jomo Kenyatta Airport, transfer to hotel)`}
                  value={formData[`travel_plan_day_description_${num}`]}
                  onChange={handleChange}
                  height={120}
                />
              </React.Fragment>
            ))}

{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <Pane marginBottom={20} key={num}>
                <FilePicker
                  label={`Upload Image ${num}`}
                  name={`image_gallery_${num}`}
                  onChange={files => handleImageChange(files, `image_gallery_${num}`)}
                  required
                  accept="image/*"
                />
              </Pane>
            ))}


          <Button appearance="primary" intent="success" onClick={handleUpdate}>
            Update Tour
          </Button>
        </Pane>
      )}
    </div>
  );
}

export default TourEdit;
