import React, {useEffect} from 'react';
import './Newsletter.css'
import AOS from "aos";
import "aos/dist/aos.css";

function Newsletter() {

  useEffect(() => {
    window.scrollTo(0,0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <form data-aos="zoom-in" className="news-form">
      <span className="title-secondary" style={{color:'white'}}>Subscribe to our newsletter.</span>
      <p className="txt_cont" style={{width:'90%',textAlign:'center',color:'white'}}>
      Join our newsletter and never miss out on exclusive offers, breathtaking safari stories, and insider tips on the best tours and destinations.
        </p>
      <div>
        <input placeholder="Enter your email" type="email" name="email" id="email-address" />
        <button className='btn' type="submit">Subscribe</button>
      </div>
    </form>
  );
}

export default Newsletter;