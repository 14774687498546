import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './PaymentResponsePage.css'
import { PDFDownloadLink, Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode'; // Import the QRCode package

// Import images from the assets folder
import logo from '../assets/Chasylmlogo.png';
import watermark from '../assets/Chasylmlogo.png';

function PaymentResponsePage() {
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const orderTrackingId = searchParams.get('OrderTrackingId');

  const [qrCodeImage, setQrCodeImage] = useState(null);

  useEffect(() => {
    if (transactionData) {
      const qrValue = `
        
            First Name: ${transactionData?.booking.firstName},
            Last Name: ${transactionData?.booking.lastName},
            Email: ${transactionData?.booking.email},
            Phone: ${transactionData?.booking.phone},
            Destination Name: ${transactionData?.booking.destinationName},
            Amount: ${transactionData?.booking.amount},
            Currency: ${transactionData?.booking.currency},
            Travel Date: ${transactionData?.booking.travel_date},
            Number of Adults: ${transactionData?.booking.adult},
            Number of Children: ${transactionData?.booking.child},
            Remaining Amount: ${transactionData?.booking.remainingAmount},
            Payment Type: ${transactionData?.paymentType},
            Transaction Date: ${transactionData?.created_date},
            Merchant Reference: ${transactionData?.booking.merchant_reference},
            Payment Method: ${transactionData?.payment_method},
            Confirmation Code: ${transactionData?.confirmation_code},
            Payment Status: ${transactionData?.payment_status_description}
      `;

      // Generate the QR code as a base64 image
      QRCode.toDataURL(qrValue, { width: 100, margin: 2 }, (err, url) => {
        if (!err) {
          setQrCodeImage(url); // Set the generated QR code image URL
        }
      });
    }
  }, [transactionData]);

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      if (orderTrackingId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/transaction-status?OrderTrackingId=${orderTrackingId}`);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setTransactionData(data);

          // Update booking status in the database
          await updateBookingStatus(data);
        } catch (err) {
          setError(err.message);
        }
      }
    };

    const updateBookingStatus = async (data) => {
      try {
        const updateResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/update-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderTrackingId: data.order_tracking_id,
            confirmationCode: data.confirmation_code,
            paymentStatusDescription: data.payment_status_description,
            paymentStatusCode: data.status_code,
            createdDate: data.created_date,
            paymentAccount: data.payment_account,
            paymentMethod: data.payment_method,
          }),
        });

        if (!updateResponse.ok) {
          throw new Error(`Failed to update booking status: ${updateResponse.status}`);
        }
      } catch (updateError) {
        console.error('Error updating booking status:', updateError.message);
      }
    };

    fetchTransactionStatus();
  }, [orderTrackingId]);

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      position: 'relative',
      backgroundColor:'#F2EED7'
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    contactInfo: {
      top: 20,
      fontSize: 10,
      textAlign: 'right',
    },
    detailsText: {
      fontSize: 12,      
    },
    logo: {
      width: 100,
      height: 80,
      marginBottom: 10
    },
    watermark: {
      position: 'absolute',
      width: '90%',
      height: '60%',
      opacity: 0.1,
      top: 100,
      left: 0,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      margin: "10px 0",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "16.66%",
      borderStyle: "solid",
      borderWidth: 1,
      padding: 8,
    },
    tableCell: {
      margin: "auto",
      fontSize: 10,
    },
    title: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 10,
    },
    section: {
      marginBottom: 10,
    },
    qrCodeContainer: {
      textAlign: 'start',
      marginTop: 20,
      marginBottom: 20,
    },
    qrCode: {
      width: 100,
      height: 100,
      marginTop: 10,
    },
  });

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>

        {/* Header Section: Logo and Contact Info */}
        <View style={styles.header}>
          {/* Logo */}
          <Image src={logo} style={styles.logo} />
          
          {/* Contact Information */}
          <View style={styles.contactInfo}>
            <Text>info@chasylmtours.com</Text>
            <Text>Tel: +254720727776</Text>
            <Text>Nairobi, Kenya</Text>
          </View>
        </View>
        
        {/* Watermark */}
        <Image src={watermark} style={styles.watermark} />

        <Text style={styles.title}>Chasylm Tours & Safaris Receipt</Text>

        {/* User Address Details */}
        <View style={styles.section}>
          <Text style={styles.detailsText}>Name: {transactionData?.booking.firstName} {transactionData?.booking.lastName}</Text>
          <Text style={styles.detailsText}>Phone: {transactionData?.booking.phone}</Text>
          <Text style={styles.detailsText}>Email: {transactionData?.booking.email}</Text>
          <Text style={styles.detailsText}>Destination: {transactionData?.booking.destinationName}</Text>
          <Text style={styles.detailsText}>Travel Date: {transactionData?.booking.travel_date}</Text>
        </View>

        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Amount (USD)</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Merchant Reference</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Date</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>No. of Adult(s)</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>No. of Child(ren)</Text></View>
          </View>

          {/* Table Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData?.payment_method}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData?.booking.amount}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData?.booking.merchant_reference}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData.created_date}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData?.booking.adult}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{transactionData?.booking.child}</Text></View>
          </View>
        </View>

         {/* User Address Details */}
         <View style={styles.section}>
          <Text style={styles.detailsText}>Tracking ID: {transactionData.order_tracking_id}</Text>
          <Text style={styles.detailsText}>Payment Account: {transactionData.payment_account}</Text>
          <Text style={styles.detailsText}>Confirmation Code: {transactionData.confirmation_code}</Text>
          <Text style={styles.detailsText}>Balance: {transactionData?.booking.remainingAmount}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.detailsText}>Message: {transactionData.message}</Text>
        </View>

        {/* QR Code Section */}
        {qrCodeImage && (
          <View style={styles.qrCodeContainer}>
            <Text style={styles.detailsText}>Scan for more details</Text>
            <Image src={qrCodeImage} style={styles.qrCode} />
          </View>
        )}

        <Text style={styles.detailsText}>Thank you for booking with us!</Text>
      </Page>
    </Document>
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!transactionData) {
    return <div>Loading transaction status...</div>;
  }

  return (
    <div className='response-container'>
      <h1>Transaction Status</h1>
      <p>Merchant Reference: {transactionData.merchant_reference}</p>
      <p>Amount: {transactionData.amount} {transactionData.currency}</p>
      <p>Status: {transactionData.payment_status_description}</p>
      <p>Payment Method: {transactionData.payment_method}</p>
      <p>Transaction Date: {new Date(transactionData.created_date).toLocaleString()}</p>
      {/* <p>Description: {transactionData.description}</p> */}
      <p>Confirmation Code: {transactionData.confirmation_code}</p>
      {/* <p>Payment Status Code: {transactionData.payment_status_code || 'N/A'}</p> */}
      {/* <p>Callback URL: <a href={transactionData.call_back_url}>{transactionData.call_back_url}</a></p> */}

      <PDFDownloadLink document={<MyDocument />} fileName="receipt.pdf">
        {({ loading }) => (loading ? 'Loading document...' : 'Download receipt')}
      </PDFDownloadLink>
    </div>
  );
}

export default PaymentResponsePage;
