import React, { useEffect, useState, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import './FAQ.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <details className="accordion-item" open={isOpen} onClick={toggleAccordion}>
      <summary>
        <span className="txt_p">{title}</span>
        <span className="accordion-icon">
          <FaAngleDown />
        </span>
      </summary>
      <div className="txt_p" ref={contentRef}>
        {content}
      </div>
    </details>
  );
};

function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  const accordionData = [
    {
      title: 'What types of tours do you offer?',
      content: 'We offer a variety of tours, including wildlife safaris, cultural tours, beach holidays, and adventure excursions. Our packages are designed to cater to different interests and durations, from short trips to extended vacations.',
    },
    {
      title: 'How do I book a tour with Chasylm Tour Company?',
      content: 'You can book a tour through our website, by contacting our customer service team via phone or email, or by visiting our office. Our representatives will guide you through the booking process.',
    },
    {
      title: 'What is included in the tour packages?',
      content:'Our tour packages typically include accommodation, meals, park entry fees, game drives, transportation, and an English-speaking guide. Specific inclusions may vary depending on the package, so please refer to the detailed itinerary for each tour.',
    },
    {
      title: 'Are international flights included in the tour packages?',
      content:'No, international flights are not included. Guests are responsible for booking their flights to and from Kenya. We can, however, assist with recommendations and airport transfer arrangements.',
    },
    {
      title: 'What is the best time to visit Kenya?',
      content:'The best time to visit Kenya depends on your interests. The dry season (June to October) is ideal for wildlife viewing, while the wet season (November to May) offers lush landscapes and fewer tourists. The Great Migration in the Maasai Mara typically occurs between July and October.',
    },
    {
      title:'Is it safe to travel in Kenya?',
      content:'Yes, Kenya is generally safe for tourists. We prioritize your safety and work with reputable local partners to ensure a secure and enjoyable experience. We recommend following common travel safety guidelines and staying informed about local conditions.',
    },
    {
      title:'Do I need vaccinations to travel to Kenya?',
      content:'Yes, certain vaccinations are recommended or required for travel to Kenya, including yellow fever, typhoid, hepatitis A, and hepatitis B. Please consult your healthcare provider for advice specific to your health needs.',
    },
    {
      title:'What measures are in place for COVID-19?',
      content:'We adhere to all local and international health guidelines to ensure the safety of our guests. This includes enhanced sanitation, social distancing measures, and providing necessary health information. Please check our website for the latest updates on COVID-19 protocols.',
    },
    {
      title:'What should I pack for my trip?',
      content:'Packing recommendations vary by tour type and season. Generally, we suggest comfortable clothing, sturdy shoes, a hat, sunscreen, insect repellent, binoculars, a camera, and any personal medications. Detailed packing lists are provided upon booking.',
    },
    {
      title:'Do I need a visa to enter Kenya?',
      content:'Most travelers require a visa to enter Kenya. You can apply for an e-visa online before your trip. Please check the specific visa requirements for your country of residence.',
    },
    {
      title:'Can I customize my tour package?',
      content:'Yes, we offer customizable tour packages to suit your preferences and needs. Contact our customer service team to discuss your requirements, and we will tailor a package for you.',
    },
    {
      title:'What types of accommodation are provided?',
      content:'We offer a range of accommodations, from luxury lodges and hotels to budget-friendly options and camping experiences. The type of accommodation depends on the tour package you choose.',
    },
    {
      title:'Are your tours suitable for children and families?',
      content:'Yes, we offer family-friendly tours and activities that are suitable for children. Please let us know if you are traveling with young children so we can make appropriate arrangements.',
    },
    {
      title:'What payment methods do you accept?',
      content:'We accept various payment methods, including credit/debit cards, bank transfers, and online payment platforms. Payment details will be provided upon booking.',
    },
    {
      title:'What is the maximum group size for your tours?',
      content:'Group sizes vary depending on the tour. We aim to keep group sizes small to ensure a personalized experience. Private tours are also available for those who prefer a more intimate experience.',
    },
    {
      title:'Will I have access to the internet during the tour?',
      content:'Many lodges and hotels offer internet access, but connectivity can be limited in remote areas. We recommend preparing for limited internet access during some parts of your trip.',
    },
    {
      title:'What if I have dietary restrictions or special needs?',
      content:'Please inform us of any dietary restrictions or special needs when booking your tour. We will make every effort to accommodate your requirements.',
    },
  ];

  return (
    <div className="accordion-container">
       <div className="faq-cont">
        <div className="cont-title">
          <p className="banner-title">F.A.Q</p>
        </div>
        </div>

        <div data-aos="zoom-in" className="faq-div">      

      {accordionData.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}

      </div>
    </div>
  );
}

export default FAQ;
