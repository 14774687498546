import React, { useState, useEffect } from 'react';
import { Tablist, Tab, Pane, TextInputField, Button, toaster } from 'evergreen-ui';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import {jwtDecode} from 'jwt-decode'; // Corrected import statement
import './UserDashboard.css';
import BookedTickets from './BookedTickets';

function UserDashboard() {
  const location = useLocation(); // Use useLocation to get the passed state
  const navigate = useNavigate(); // Use useNavigate to handle routing
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userData, setUserData] = useState({
    id: '',
    username: '',
    email: '',
    phonenumber: '',
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const tabs = [
    'User Profile',
    'Change Password',
    'Booked Tickets',
    'Notifications',
    'Support/Contact Us',
    'Reviews & Feedback',
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      const token = location.state?.token; // Get the token from location state
      if (!token) {
        toaster.danger('Please log in to access this page');
        return;
      }

      try {
        const decodedToken = jwtDecode(token);

        // Check if the token is expired
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decodedToken.exp < currentTime) {
          throw new Error('Token has expired, please log in again.');
        }

        if (!decodedToken || !decodedToken.id) {
          throw new Error('Invalid Token');
        }

        const userId = decodedToken.id;

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch user data');
        }

        const data = await response.json();
        setUserData({
          id: data.id,
          username: data.username,
          email: data.email,
          phonenumber: data.phonenumber,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        toaster.danger(error.message);
      }
    };

    fetchUserData();
  }, [location.state]); // Re-run the effect if location.state changes

  const handleProfileUpdate = async () => {
    try {
      const token = location.state?.token; // Get the token from location state
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update profile');
      }

      toaster.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      toaster.danger(error.message);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      toaster.danger('Passwords do not match');
      return;
    }

    try {
      const token = location.state?.token; // Get the token from location state
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userData.id}/password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to change password');
      }

      toaster.success('Password updated successfully');
      // Clear password fields after successful change
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      toaster.danger(error.message);
    }
  };

  const renderTabContent = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <Pane>
            <TextInputField
              placeholder="Username"
              value={userData.username}
              onChange={(e) => setUserData({ ...userData, username: e.target.value })}
            />
            <TextInputField
              placeholder="Email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
            />
            <TextInputField
              placeholder="Phone Number"
              value={userData.phonenumber}
              onChange={(e) => setUserData({ ...userData, phonenumber: e.target.value })}
            />
            <Button appearance="primary" onClick={handleProfileUpdate}>
              Update Profile
            </Button>
          </Pane>
        );
      case 1:
        return (
          <Pane>
            <TextInputField
              placeholder="Current Password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <TextInputField
              placeholder="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextInputField
              placeholder="Confirm New Password"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <Button appearance="primary" onClick={handleChangePassword}>
              Change Password
            </Button>
          </Pane>
        );
        case 2:
          return <BookedTickets userEmail={userData.email} userPhone={userData.phonenumber} />; // Pass user email and phone
      default:
        return null;
    }
  };

  return (
    <div className="user_dashboard_container">
      <div className="usr_top">
        <p style={{ color: '#000' }}>{userData.username}</p>
        <button
          onClick={() => {
            localStorage.removeItem('token'); // Remove token from localStorage
            navigate('/'); // Redirect to home page
          }}
        >
          Logout
        </button>
      </div>

      <div className="usr_bottom">
        <div className="tablist_container">
          <Tablist>
            {tabs.map((tab, index) => (
              <Tab
                key={tab}
                id={tab}
                onSelect={() => setSelectedIndex(index)}
                isSelected={index === selectedIndex}
                aria-controls={`panel-${tab}`}
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
        </div>

        <div className="pane_container">
          <Pane
            key={tabs[selectedIndex]}
            id={`panel-${tabs[selectedIndex]}`}
            role="tabpanel"
            aria-labelledby={tabs[selectedIndex]}
            padding={16}
          >
            {renderTabContent()}
          </Pane>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
