import React, {useEffect} from 'react';
import './Branches.css';
import AOS from "aos";
import "aos/dist/aos.css";
import branch_img from '../assets/World Map.jpg';

function Branches() {
  useEffect(() => {
    // window.scrollTo(0,0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div data-aos="zoom-in" className="branch-container">
      <div className="branch-heading">
        <p className='title-secondary'>Our Branches</p>
      </div>
      <div className="branch-img">
        <img src={branch_img} alt="Branches" />
      </div>
    </div>
  );
}

export default Branches;
