import React, { useEffect, useState } from 'react';
import './Receipts.css'
import { Pane, Table, Spinner, Text, Strong, Alert, TextInputField, Select } from 'evergreen-ui';
import { PDFDownloadLink, Page, Text as PDFText, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode'; // Import the QRCode package

// Import images from the assets folder
import logo from '../../assets/Chasylmlogo.png';
import watermark from '../../assets/Chasylmlogo.png';

function Receipts() {
  const [receipts, setReceipts] = useState([]);
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterDuration, setFilterDuration] = useState('');

  const [qrCodeImage, setQrCodeImage] = useState(null);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/receipts`); // API endpoint to get all receipts
        if (!response.ok) {
          throw new Error('Failed to fetch receipts');
        }
        const data = await response.json();
        setReceipts(data);
        setFilteredReceipts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReceipts();
  }, []);

  // Filter receipts based on search query
  useEffect(() => {
    const filtered = receipts.filter(receipt => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        receipt.email.toLowerCase().includes(searchTerm) ||
        receipt.phone.toLowerCase().includes(searchTerm) ||
        receipt.firstName.toLowerCase().includes(searchTerm) ||
        receipt.lastName.toLowerCase().includes(searchTerm) ||
        receipt.destinationName.toLowerCase().includes(searchTerm)
      );
    });
    setFilteredReceipts(filtered);
  }, [searchQuery, receipts]);

  // Filter by duration (week/month)
  useEffect(() => {
    let filtered = [...receipts];

    if (filterDuration === 'month') {
      filtered = filtered.filter(receipt => {
        const receiptDate = new Date(receipt.date);
        const today = new Date();
        return receiptDate.getMonth() === today.getMonth() && receiptDate.getFullYear() === today.getFullYear();
      });
    } else if (filterDuration === 'week') {
      filtered = filtered.filter(receipt => {
        const receiptDate = new Date(receipt.date);
        const today = new Date();
        const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));
        return receiptDate >= oneWeekAgo && receiptDate <= new Date();
      });
    }

    setFilteredReceipts(filtered);
  }, [filterDuration, receipts]);

  useEffect(() => {
    if (filteredReceipts.length > 0) {
      const currentReceipt = filteredReceipts[0]; // Or select the specific receipt you want
  
      const qrValue = `
        Tour ID: ${currentReceipt.tour_id},
        First Name: ${currentReceipt.firstName},
        Last Name: ${currentReceipt.lastName},
        Email: ${currentReceipt.email},
        Phone: ${currentReceipt.phone},
        Destination Name: ${currentReceipt.destinationName},  
        Amount: ${currentReceipt.amount},
        Currency: ${currentReceipt.currency},
        Travel Date: ${currentReceipt.travel_date},
        Number of Adults: ${currentReceipt.adult},
        Number of Children: ${currentReceipt.child},
        Remaining Amount: ${currentReceipt.remainingAmount}
        Payment Type: ${currentReceipt.paymentType},
        Transaction Date: ${currentReceipt.created_date},
        Order Tracking ID: ${currentReceipt.order_tracking_id},
        Merchant Reference: ${currentReceipt.merchant_reference},
        Payment Method: ${currentReceipt.payment_method},
        Confirmation Code: ${currentReceipt.confirmation_code},
        Payment Status Description: ${currentReceipt.payment_status_description},
        Description: ${currentReceipt.description},
        Message: ${currentReceipt.message},
        Payment Account: ${currentReceipt.payment_account},
        Payment Status Code: ${currentReceipt.payment_status_code},
        Status: ${currentReceipt.status},
      `;
  
      // Generate the QR code as a base64 image
      QRCode.toDataURL(qrValue, { width: 100, margin: 2 }, (err, url) => {
        if (!err) {
          setQrCodeImage(url); // Set the generated QR code image URL
        }
      });
    }
  }, [filteredReceipts]); // Add filteredReceipts as a dependency
  

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      position: 'relative',
      backgroundColor:'#F2EED7'
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    contactInfo: {
      top: 20,
      fontSize: 10,
      textAlign: 'right',
    },
    detailsText: {
      fontSize: 12,      
    },
    logo: {
      width: 100,
      height: 80,
      marginBottom: 10
    },
    watermark: {
      position: 'absolute',
      width: '90%',
      height: '60%',
      opacity: 0.1,
      top: 100,
      left: 0,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      margin: "10px 0",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "16.66%",
      borderStyle: "solid",
      borderWidth: 1,
      padding: 8,
    },
    tableCell: {
      margin: "auto",
      fontSize: 10,
    },
    title: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 10,
    },
    section: {
      marginBottom: 10,
    },
    qrCodeContainer: {
      textAlign: 'start',
      marginTop: 20,
      marginBottom: 20,
    },
    qrCode: {
      width: 100,
      height: 100,
      marginTop: 10,
    },
  });

  // PDF document for printing receipt
  const ReceiptPDF = ({ receipt }) => (
    <Document>
      <Page style={styles.page}>

        {/* Header Section: Logo and Contact Info */}
        <View style={styles.header}>
          {/* Logo */}
          <Image src={logo} style={styles.logo} />
          
          {/* Contact Information */}
          <View style={styles.contactInfo}>
            <PDFText>info@chasylmtours.com</PDFText>
            <PDFText>Tel: +254720727776</PDFText>
            <PDFText>Nairobi, Kenya</PDFText>
          </View>
        </View>
        
        {/* Watermark */}
        <Image src={watermark} style={styles.watermark} />

        <Text style={styles.title}>Chasylm Tours & Safaris Receipt</Text>

        {/* User Address Details */}
        <View style={styles.section}>
          <PDFText style={styles.detailsText}>Name: {receipt.firstName} {receipt.lastName}</PDFText>
          <PDFText style={styles.detailsText}>Phone: {receipt.phone}</PDFText>
          <PDFText style={styles.detailsText}>Email: {receipt.email}</PDFText>
          <PDFText style={styles.detailsText}>Destination: {receipt.destinationName}</PDFText>
          <PDFText style={styles.detailsText}>Travel Date: {receipt.travel_date}</PDFText>
        </View>

        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>Payment Method</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>Amount (USD)</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>Merchant Reference</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>Payment Date</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>No. of Adult(s)</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>No. of Child(ren)</PDFText></View>
          </View>

          {/* Table Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.payment_method}</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.amount}</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.merchant_reference}</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.created_date}</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.adult}</PDFText></View>
            <View style={styles.tableCol}><PDFText style={styles.tableCell}>{receipt.child}</PDFText></View>
          </View>
        </View>

         {/* User Address Details */}
         <View style={styles.section}>
          <PDFText style={styles.detailsText}>Tracking ID: {receipt.order_tracking_id}</PDFText>
          <PDFText style={styles.detailsText}>Payment Account: {receipt.payment_account}</PDFText>
          <PDFText style={styles.detailsText}>Confirmation Code: {receipt.confirmation_code}</PDFText>
          <PDFText style={styles.detailsText}>Balance: {receipt.remainingAmount}</PDFText>
        </View>

        <View style={styles.section}>
          <PDFText style={styles.detailsText}>Message: {receipt.message}</PDFText>
        </View>

        {/* QR Code Section */}
        {qrCodeImage && (
          <View style={styles.qrCodeContainer}>
            <PDFText style={styles.detailsText}>Scan for more details</PDFText>
            <Image src={qrCodeImage} style={styles.qrCode} />
          </View>
        )}

        <PDFText style={styles.detailsText}>Thank you for booking with us!</PDFText>
      </Page>
    </Document>
  );

  if (loading) {
    return (
      <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
        <Text marginLeft={16}>Loading receipts...</Text>
      </Pane>
    );
  }

  if (error) {
    return (
      <Pane>
        <Alert intent="danger" title="Error loading receipts">
          {error}
        </Alert>
      </Pane>
    );
  }

  return (
    <Pane padding={16} className='receipts-container' style={{marginTop:'10rem'}}>
      <Text size={600} marginBottom={16} display="block">
        <Strong>Receipts</Strong>
      </Text>

      {/* Search Input */}
      <TextInputField
        label="Search Receipts"
        placeholder="Search by email, phone, first name, last name"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />

      {/* Filter Duration */}
      <Select
        onChange={e => setFilterDuration(e.target.value)}
        placeholder="Filter by duration"
        marginBottom={16}
      >
        <option value="">All</option>
        <option value="week">This Week</option>
        <option value="month">This Month</option>
      </Select>

      {filteredReceipts.length === 0 ? (
        <Text>No receipts available</Text>
      ) : (
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>ID</Table.TextHeaderCell>
            <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
            <Table.TextHeaderCell>Currency</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>Travel Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Email</Table.TextHeaderCell>
            <Table.TextHeaderCell>Phone</Table.TextHeaderCell>
            <Table.TextHeaderCell>First Name</Table.TextHeaderCell>
            <Table.TextHeaderCell>Last Name</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Order Tracking ID</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>Merchant Reference</Table.TextHeaderCell>
            <Table.TextHeaderCell>Payment Method</Table.TextHeaderCell>
            <Table.TextHeaderCell>Confirmation Code</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Payment Status Description</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>Description</Table.TextHeaderCell>
            <Table.TextHeaderCell>Message</Table.TextHeaderCell>
            <Table.TextHeaderCell>Payment Account</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Payment Status Code</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>Adults</Table.TextHeaderCell>
            <Table.TextHeaderCell>Children</Table.TextHeaderCell>
            <Table.TextHeaderCell>Payment Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Remaining Amount</Table.TextHeaderCell>
            <Table.TextHeaderCell>Destination</Table.TextHeaderCell>
            {/* <Table.TextHeaderCell>Tour ID</Table.TextHeaderCell> */}
            <Table.TextHeaderCell>Print</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {filteredReceipts.map((receipt) => (
              <Table.Row key={receipt.id}>
                <Table.TextCell>{receipt.id}</Table.TextCell>
                <Table.TextCell>{receipt.amount}</Table.TextCell>
                <Table.TextCell>{receipt.currency}</Table.TextCell>
                <Table.TextCell>{receipt.status}</Table.TextCell>
                <Table.TextCell>{new Date(receipt.travel_date).toLocaleDateString()}</Table.TextCell>
                <Table.TextCell>{receipt.email}</Table.TextCell>
                <Table.TextCell>{receipt.phone}</Table.TextCell>
                <Table.TextCell>{receipt.firstName}</Table.TextCell>
                <Table.TextCell>{receipt.lastName}</Table.TextCell>
                {/* <Table.TextCell>{receipt.order_tracking_id}</Table.TextCell> */}
                <Table.TextCell>{receipt.merchant_reference}</Table.TextCell>
                <Table.TextCell>{receipt.payment_method}</Table.TextCell>
                <Table.TextCell>{receipt.confirmation_code}</Table.TextCell>
                {/* <Table.TextCell>{receipt.payment_status_description}</Table.TextCell> */}
                <Table.TextCell>{receipt.description}</Table.TextCell>
                <Table.TextCell>{receipt.message}</Table.TextCell>
                <Table.TextCell>{receipt.payment_account}</Table.TextCell>
                {/* <Table.TextCell>{receipt.payment_status_code}</Table.TextCell> */}
                <Table.TextCell>{receipt.adult}</Table.TextCell>
                <Table.TextCell>{receipt.child}</Table.TextCell>
                <Table.TextCell>{receipt.paymentType}</Table.TextCell>
                <Table.TextCell>{receipt.remainingAmount}</Table.TextCell>                
                <Table.TextCell>{receipt.destinationName}</Table.TextCell>
                {/* <Table.TextCell>{receipt.tour_id}</Table.TextCell> */}
                <Table.TextCell>
                  <PDFDownloadLink
                    document={<ReceiptPDF receipt={receipt} />}
                    fileName={`receipt-${receipt.id}.pdf`}
                  >
                    {({ loading }) => (loading ? 'Loading document...' : 'Print')}
                  </PDFDownloadLink>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Pane>
  );
}

export default Receipts;
