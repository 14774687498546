import React, {useEffect} from 'react'
import './AboutUs.css'
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from './Newsletter';
import img1 from '../assets/company-image2.jpeg'
import img2 from '../assets/company-image.jpeg'

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0,0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
   <div className="about-container">
     <div className="banner-about">
      <div className="banner-sect">
      <p className='banner-title'>About Us</p>
      </div>    
    </div>
    
    <div className="about-content">

    <p data-aos="fade-up" className='txt_cont'>
    Chasylm Tours and Safaris Company is a premier travel and tour operator based in Kenya, with an office in the UK renowned for offering unique, personalized, and memorable travel experiences. Specializing in both local and international tourism, Chasylm Tours and Safaris is dedicated to showcasing the diverse beauty and cultural richness of Kenya while ensuring the highest standards of service and customer satisfaction.
      </p>

      <p data-aos="fade-up" className='title-primary'style={{textAlign:'center'}}>
      Mission of Chasylm Tours & Safaris Company
      </p>

      <p data-aos="fade-up" className='txt_cont'>The mission of Chasylm Tours & Safaris Company is to provide exceptional, sustainable, and memorable travel experiences that showcase the diverse beauty and cultural richness of Kenya. We are committed to delivering personalized services that exceed our clients' expectations, promoting responsible tourism practices, and supporting the conservation of Kenya's natural and cultural heritage. Through our dedication to excellence, we aim to create lasting memories for our clients while contributing positively to local communities and the environment.</p>

      <p data-aos="fade-up" className='title-primary' style={{textAlign:'center'}}>
      Vision of Chasylm Tours & Safaris Company
      </p>

      <p data-aos="fade-up" className='txt_cont'>The vision of Chasylm Tour Company is to be the leading tour operator in Kenya, recognized globally for our commitment to excellence, sustainability, and innovation in the travel industry. We aspire to inspire a deep appreciation for Kenya’s natural wonders and cultural heritage while fostering sustainable tourism practices that benefit local communities and preserve the environment for future generations. Through our passion for travel and dedication to customer satisfaction, we aim to create transformative travel experiences that leave a lasting positive impact on both our clients and the destinations we serve.
      </p>
    <div className="about-grid">
        <div data-aos="fade-left" className="about-content-grid">
            <img src={img1} alt="" className='img_aboutus'/>
        </div>
        <div data-aos="fade-right"className="about-content-grid">
          <p className='title-primary'>Company Background</p>
            <p className='txt_cont'>
            Founded in 2011, Chasylm Tour Company was established with a vision to promote East Africa as a top travel destination by providing exceptional tour packages and travel services. The company is headquartered in Nairobi the vibrant capital city of Kenya and a branch in the UK and has a team of experienced travel consultants and tour guides who are passionate about creating unforgettable journeys for their clients.
              </p>
        </div>
        <div data-aos="fade-right" className="about-content-grid">
        <p className='title-primary'>Commitment to Sustainability</p>
            <p className='txt_cont'>
            Chasylm Tours & Safaris Company is committed to promoting sustainable tourism practices. The company actively engages in conservation efforts, supports local communities, and strives to minimize its environmental impact. By partnering with eco-friendly lodges and promoting responsible tourism, Chasylm aims to ensure that Kenya’s natural and cultural treasures are preserved for future generations.
              </p>
        </div>
        <div data-aos="fade-left" className="about-content-grid">
        <img src={img2} alt="" className='img_aboutus' />
        </div>
    </div>

    <div data-aos="fade-up" style={{marginBottom:"3rem"}}>
    <p className='title-primary' style={{textAlign:'center'}}>Core Values of Chasylm Tours & Safaris Company</p>
    <ul className='core-list txt_cont'>
      <li><b>Excellence</b> - We strive for the highest standards in all aspects of our operations, ensuring top-quality services and unforgettable experiences for our clients.</li>
      <li><b>Customer Satisfaction</b> - Our clients are at the heart of everything we do. We are committed to understanding their needs, exceeding their expectations, and providing exceptional service from start to finish.</li>
      <li><b>Sustainability</b> - We are dedicated to promoting sustainable tourism practices that protect and preserve Kenya’s natural and cultural resources for future generations. We support conservation efforts and eco-friendly initiatives.</li>
      <li><b>Integrity</b> - We conduct our business with honesty, transparency, and accountability. We build trust with our clients, partners, and communities through ethical practices and reliable service. </li>
      <li><b>Innovation</b> - We embrace creativity and innovation to continuously improve our services and offer unique, tailored travel experiences that inspire and delight our clients.</li>
      <li><b>Community Engagement</b> - We believe in giving back to the communities we operate in. We support local businesses, promote cultural heritage, and contribute to the economic development of the areas we visit. </li>
      <li><b>Passion for Travel</b> - Our team is passionate about travel and exploring the beauty of Kenya. We share this passion with our clients, creating journeys that inspire a love for adventure and discovery.</li>
      <li><b>Respect for Diversity</b> - We celebrate the diverse cultures, traditions, and landscapes of Kenya. We respect and honor the people, wildlife, and environments that make each destination unique.</li>
      <li><b>Teamwork</b> - Our success is built on collaboration and teamwork. We foster a positive and supportive work environment where every team member contributes to our shared goals.</li>
      <li><b>Safety</b> - We prioritize the safety and well-being of our clients and staff. We adhere to the highest safety standards and ensure that every journey is conducted with care and attention to detail.</li>
    </ul>
      </div>
   </div>
   <Newsletter/>
   </div>
  )
}

export default AboutUs

