import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination, Combobox } from 'evergreen-ui';
import './Destinations.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from './Newsletter';

function Destinations() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [destinations, setDestinations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const destinationsPerPage = 8;
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams(); // Correctly destructured

  // Fetch destinations from API
  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations`);
        if (!response.ok) {
          throw new Error('Failed to fetch destinations');
        }
        const data = await response.json();
        setDestinations(data);
      } catch (error) {
        console.error('Error fetching destinations:', error);
      }
    };

    fetchDestinations();
  }, []);

  const handleClick = (destination) => {
    setSearchParams({ id: destination.id }); // Update to set correct ID in params
    navigate(`/destination-info?id=${destination.id}`); // Correct ID reference
  };

  // Filter destinations based on selected country
  const filteredDestinations = selectedCountry === "All Countries"
    ? destinations
    : destinations.filter(destination => destination.country === selectedCountry);

  // Get current destinations for the current page
  const indexOfLastDestination = currentPage * destinationsPerPage;
  const indexOfFirstDestination = indexOfLastDestination - destinationsPerPage;
  const currentDestinations = filteredDestinations.slice(indexOfFirstDestination, indexOfLastDestination);

  // Pagination logic
  const totalPages = Math.ceil(filteredDestinations.length / destinationsPerPage);

  return (
    <div className="destination-container">
      <div className="banner-dest">
        <div className="banner-sect">
          <p className='banner-title'>Destination List</p>
        </div>
      </div>

      <div data-aos="zoom-in-up" className="dest-content">
        <p className='title-secondary'>Explore East Africa's Premier Safari and Tour Destinations.</p>
        <p className='txt_cont'>Uncover the magic of East Africa with our curated selection of breathtaking destinations. From the iconic savannas teeming with wildlife to pristine beaches and cultural landmarks, each location offers a unique adventure. Whether you're seeking thrilling game drives, serene landscapes, or immersive cultural experiences, our destinations provide the perfect backdrop for an unforgettable journey. Discover the heart of Africa and experience its beauty, diversity, and charm.</p>
      </div>

      <div data-aos="zoom-in" className="filter_tour_list">
        <Combobox
          openOnFocus
          width="100%"
          height={40}
          items={['All Countries', 'Kenya', 'Uganda', 'Tanzania']}
          onChange={selected => setSelectedCountry(selected)}
          placeholder="Select Country"
          initialSelectedItem="All Countries"
        />
      </div>

      <div data-aos="zoom-in" className="dest_box">
        {currentDestinations.map(destination => (
          <div className="dest_gallery" key={destination.destination_id} onClick={() => handleClick(destination)}>
            <img src={`${process.env.REACT_APP_BASE_URL}/${destination.image_preview}`} alt={destination.destination_name} className='dest_img' />
            <div className="in-imgtxt">
              <p className='in-float-imgtxt'>{destination.destination_name}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination-container">
        <Pagination
          page={currentPage}
          totalPages={totalPages}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>

      <Newsletter />
    </div>
  );
}

export default Destinations;
