import React, { useState, useEffect } from 'react';
import { Pane, Table, Button, TextInput, Heading, Dialog, toaster } from 'evergreen-ui';
import './UserEdit.css';

function UsersList() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [editingUserId, setEditingUserId] = useState(null); // Track user being edited
  const [editedUser, setEditedUser] = useState({ username: '', email: '', phonenumber: '', role: '' });
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // Track user for deletion

  // Fetch all users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/user-edit`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUsers(data);
        } else {
          setMessage(data.message);
        }
        setLoading(false);
      } catch (error) {
        setMessage('Error fetching users data');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setEditedUser({ username: user.username, email: user.email, phonenumber: user.phonenumber, role: user.role });
  };

  const handleSaveClick = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(editedUser),
      });
      const data = await response.json();
      if (response.ok) {
        // Update user list with the edited user
        setUsers(users.map(user => (user.id === userId ? data : user)));
        toaster.success('User updated successfully');
      } else {
        toaster.danger(data.message || 'Failed to update user');
      }
      setEditingUserId(null); // Exit edit mode
    } catch (error) {
      toaster.danger('Error updating user');
    }
  };

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setIsDeleteDialogShown(true);
  };

  const confirmDeleteUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/${selectedUserId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        setUsers(users.filter(user => user.id !== selectedUserId)); // Remove user from list
        toaster.success('User deleted successfully');
      } else {
        const data = await response.json();
        toaster.danger(data.message || 'Failed to delete user');
      }
    } catch (error) {
      toaster.danger('Error deleting user');
    }
    setIsDeleteDialogShown(false);
    setSelectedUserId(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Pane className="users-list-container" padding={16}>
      <Heading size={600} marginBottom={24}>All Users</Heading>
      {message && <Pane>{message}</Pane>}
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Username</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Phone Number</Table.TextHeaderCell>
          <Table.TextHeaderCell>Role</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {users.map(user => (
            <Table.Row key={user.id}>
              <Table.TextCell>
                {editingUserId === user.id ? (
                  <TextInput
                    value={editedUser.username}
                    onChange={(e) => setEditedUser({ ...editedUser, username: e.target.value })}
                  />
                ) : (
                  user.username
                )}
              </Table.TextCell>
              <Table.TextCell>
                {editingUserId === user.id ? (
                  <TextInput
                    value={editedUser.email}
                    onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                  />
                ) : (
                  user.email
                )}
              </Table.TextCell>
              <Table.TextCell>
                {editingUserId === user.id ? (
                  <TextInput
                    value={editedUser.phonenumber}
                    onChange={(e) => setEditedUser({ ...editedUser, phonenumber: e.target.value })}
                  />
                ) : (
                  user.phonenumber
                )}
              </Table.TextCell>
              <Table.TextCell>
                {editingUserId === user.id ? (
                  <TextInput
                    value={editedUser.role}
                    onChange={(e) => setEditedUser({ ...editedUser, role: e.target.value })}
                  />
                ) : (
                  user.role
                )}
              </Table.TextCell>
              <Table.TextCell>
                {editingUserId === user.id ? (
                  <Button appearance="primary" onClick={() => handleSaveClick(user.id)}>Save</Button>
                ) : (
                  <Button appearance="primary" onClick={() => handleEditClick(user)} marginRight={8}>Edit</Button>
                )}
                <Button appearance="primary" intent="danger" onClick={() => handleDeleteClick(user.id)}>Delete</Button>
              </Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Dialog
        isShown={isDeleteDialogShown}
        title="Delete User"
        intent="danger"
        onCloseComplete={() => setIsDeleteDialogShown(false)}
        onConfirm={confirmDeleteUser}
        confirmLabel="Delete"
      >
        Are you sure you want to delete this user? This action cannot be undone.
      </Dialog>
    </Pane>
  );
}

export default UsersList;
