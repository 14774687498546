import React, { useState, useEffect } from 'react';
import { Table, Button } from 'evergreen-ui';
import { PDFDownloadLink, Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import QRCode from 'qrcode'; // Import the QRCode package

// Import images from the assets folder
import logo from '../../assets/Chasylmlogo.png';
import watermark from '../../assets/Chasylmlogo.png';

// Define the PDF document layout
const ReceiptDocument = ({ ticket }) => {

  const [qrCodeImage, setQrCodeImage] = useState(null);

  useEffect(() => {
    // Combine ticket information to store in QR code
    // const qrValue = `Ticket ID: ${ticket.id}, Amount: ${ticket.amount}, Confirmation Code: ${ticket.confirmation_code}`;
    const qrValue = `
    Tour ID: ${ticket.tour_id},
    First Name: ${ticket.firstName},
    Last Name: ${ticket.lastName},
    Email: ${ticket.email},
    Phone: ${ticket.phone},
    Destination Name: ${ticket.destinationName},  
    Amount: ${ticket.amount},
    Currency: ${ticket.currency},
    Travel Date: ${ticket.travel_date},
    Number of Adults: ${ticket.adult},
    Number of Children: ${ticket.child},
    Remaining Amount: ${ticket.remainingAmount}
    Payment Type: ${ticket.paymentType},
    Transaction Date: ${ticket.created_date},
    Order Tracking ID: ${ticket.order_tracking_id},
    Merchant Reference: ${ticket.merchant_reference},
    Payment Method: ${ticket.payment_method},
    Confirmation Code: ${ticket.confirmation_code},
    Payment Status Description: ${ticket.payment_status_description},
    Description: ${ticket.description},
    Message: ${ticket.message},
    Payment Account: ${ticket.payment_account},
    Payment Status Code: ${ticket.payment_status_code},
    Status: ${ticket.status},  
`;

    // Generate the QR code as a base64 image
    QRCode.toDataURL(qrValue, { width: 100, margin: 2 }, (err, url) => {
      if (!err) {
        setQrCodeImage(url); // Set the generated QR code image URL
      }
    });
  }, [ticket]); 

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      position: 'relative',
      backgroundColor:'#F2EED7'
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    contactInfo: {
      top: 20,
      fontSize: 10,
      textAlign: 'right',
    },
    detailsText: {
      fontSize: 12,      
    },
    logo: {
      width: 100,
      height: 80,
      marginBottom: 10
    },
    watermark: {
      position: 'absolute',
      width: '90%',
      height: '60%',
      opacity: 0.1,
      top: 100,
      left: 0,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      margin: "10px 0",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "16.66%",
      borderStyle: "solid",
      borderWidth: 1,
      padding: 8,
    },
    tableCell: {
      margin: "auto",
      fontSize: 10,
    },
    title: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 10,
    },
    section: {
      marginBottom: 10,
    },
    qrCodeContainer: {
      textAlign: 'start',
      marginTop: 20,
      marginBottom: 20,
    },
    qrCode: {
      width: 100,
      height: 100,
      marginTop: 10,
    },
  });

  return (
    <Document>
      <Page style={styles.page}>

        {/* Header Section: Logo and Contact Info */}
        <View style={styles.header}>
          {/* Logo */}
          <Image src={logo} style={styles.logo} />
          
          {/* Contact Information */}
          <View style={styles.contactInfo}>
            <Text>info@chasylmtours.com</Text>
            <Text>Tel: +254720727776</Text>
            <Text>Nairobi, Kenya</Text>
          </View>
        </View>
        
        {/* Watermark */}
        <Image src={watermark} style={styles.watermark} />

        <Text style={styles.title}>Chasylm Tours & Safaris Receipt</Text>

        {/* User Address Details */}
        <View style={styles.section}>
          <Text style={styles.detailsText}>Name: {ticket.firstName} {ticket.lastName}</Text>
          <Text style={styles.detailsText}>Phone: {ticket.phone}</Text>
          <Text style={styles.detailsText}>Email: {ticket.email}</Text>
          <Text style={styles.detailsText}>Destination: {ticket.destinationName}</Text>
          <Text style={styles.detailsText}>Travel Date: {ticket.travel_date}</Text>
        </View>

        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Amount (USD)</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Merchant Reference</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Date</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>No. of Adult(s)</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>No. of Child(ren)</Text></View>
          </View>

          {/* Table Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.payment_method}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.amount}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.merchant_reference}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.created_date}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.adult}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ticket.child}</Text></View>
          </View>
        </View>

         {/* User Address Details */}
         <View style={styles.section}>
          <Text style={styles.detailsText}>Tracking ID: {ticket.order_tracking_id}</Text>
          <Text style={styles.detailsText}>Payment Account: {ticket.payment_account}</Text>
          <Text style={styles.detailsText}>Confirmation Code: {ticket.confirmation_code}</Text>
          <Text style={styles.detailsText}>Balance: {ticket.remainingAmount}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.detailsText}>Message: {ticket.message}</Text>
        </View>

        {/* QR Code Section */}
        {qrCodeImage && (
          <View style={styles.qrCodeContainer}>
            <Text style={styles.detailsText}>Scan for more details</Text>
            <Image src={qrCodeImage} style={styles.qrCode} />
          </View>
        )}

        <Text style={styles.detailsText}>Thank you for booking with us!</Text>
      </Page>
    </Document>
  );
};

const BookedTickets = ({ userEmail, userPhone }) => {
  const [bookedTickets, setBookedTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch booked tickets from the backend
  const fetchBookedTickets = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/receipts`);
      if (!response.ok) {
        throw new Error(`Failed to fetch booked tickets: ${response.status}`);
      }
      const ticketsData = await response.json();
      setBookedTickets(ticketsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookedTickets();
  }, []);

  const filteredTickets = bookedTickets.filter(ticket => 
    ticket.email === userEmail || ticket.phone === userPhone
  );

  if (loading) return <div>Loading booked tickets...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Booked Tickets</h2>
      {filteredTickets.length === 0 ? (
        <p>No tickets booked yet.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Amount (KES)</th>
              <th>Status</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTickets.map(ticket => (
              <tr key={ticket.id}>
                <td>{ticket.id}</td>
                <td>{ticket.amount}</td>
                <td>{ticket.status}</td>
                <td>{ticket.created_date}</td>
                <td>
                  <PDFDownloadLink
                    document={<ReceiptDocument ticket={ticket} />}
                    fileName={`receipt_${ticket.id}.pdf`}
                  >
                    {({ loading }) => loading ? 'Loading document...' : <Button>Print Receipt</Button>}
                  </PDFDownloadLink>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default BookedTickets;
