import React, { useEffect, useState } from 'react';
import './Hero.css';
import video from '../assets/video2.mp4';
import { Link, useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button, SelectMenu } from 'evergreen-ui';

function Hero() {
  const navigate = useNavigate();
  const [safariType, setSafariType] = useState('Safari Types');
  const [tourLocation, setTourLocation] = useState('Tour Locations');
  const [tourPackage, setTourPackage] = useState('Available Tour Packages');
  const [allTours, setAllTours] = useState([]); // Store all tours data
  const [filteredLocations, setFilteredLocations] = useState([]); // Store filtered locations
  const [filteredTourPackages, setFilteredTourPackages] = useState([]); // Store filtered tour packages

  // Fetching tour data from API (replace with your actual API endpoint)
  useEffect(() => {
    const fetchTourData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours`);
        const data = await response.json();
        setAllTours(data); // Save all fetched tours
      } catch (error) {
        console.error('Error fetching tour data:', error);
      }
    };

    fetchTourData();
    AOS.init();
    AOS.refresh();
  }, []);

  // Function to filter locations based on selected safari type
  useEffect(() => {
    if (safariType === 'Safari Types') {
      const locationCounts = allTours.reduce((acc, tour) => {
        acc[tour.country] = (acc[tour.country] || 0) + 1;
        return acc;
      }, {});

      const locationOptions = Object.entries(locationCounts).map(([country, count]) => ({
        label: `${country} (${count})`,
        value: country,
      }));

      setFilteredLocations(locationOptions);
    } else {
      const filteredTours = allTours.filter(tour => tour.safari_type === safariType);
      const locationCounts = filteredTours.reduce((acc, tour) => {
        acc[tour.country] = (acc[tour.country] || 0) + 1;
        return acc;
      }, {});

      const locationOptions = Object.entries(locationCounts).map(([country, count]) => ({
        label: `${country} (${count})`,
        value: country,
      }));

      setFilteredLocations(locationOptions);
    }
  }, [safariType, allTours]);

  // Function to filter tour packages based on selected safari type and tour location
  useEffect(() => {
    if (safariType !== 'Safari Types' && tourLocation !== 'Tour Locations') {
      const filteredPackages = allTours
        .filter(tour => tour.safari_type === safariType && tour.country === tourLocation)
        .map(tour => ({
          label: `${tour.duration} Days ${tour.destination_title}`,
          value: tour.id,
        }));
      setFilteredTourPackages(filteredPackages);
    } else {
      setFilteredTourPackages([]);
    }
  }, [safariType, tourLocation, allTours]);

  // Handle search functionality to navigate to the selected tour package
  const handleSearch = () => {
    const selectedPackage = filteredTourPackages.find(pkg => pkg.label === tourPackage);
    if (selectedPackage) {
      navigate(`/tour-packages?id=${selectedPackage.value}`); // Navigate to the specific tour page 
    } else {
      alert('Please select a valid tour package');
    }
  };

  return (
    <div className="hero-container">
      <div className="video-bg">
        <video src={video} autoPlay loop muted className='video-css'></video>
      </div>

      <div data-aos="zoom-in-up" className="hero-txt">
        <p className='h-title'>Unlock your travel dreams with us.</p>
        <p className='h-subtle'>Discover the world's most adventurous nature</p>
        <Link to='/destinations'>
          <button className='customBtn'>Find out more</button>
        </Link>
      </div>

      <div className="float-div">
        <div className="inp-sect">
          <p className='h-subtle'>Find Adventure That Suits Your Needs</p>
          <div className="inp-items">
            {/* Dropdown for Safari Type */}
            <SelectMenu
              title="Select Safari Type"
              options={[
                { label: 'All Safari Types', value: 'Safari Types' },
                { label: 'Wildlife Safari', value: 'wildlife' },
                { label: 'Excursions', value: 'excursions' },
                { label: 'Desert Safari', value: 'desert' },
                { label: 'Mountain Safari', value: 'mountain' }
              ]}
              selected={safariType}
              onSelect={item => setSafariType(item.label)}
            >
              <Button
                className='input-custom'
                width="100%"
                style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc' }}
              >
                {safariType}
              </Button>
            </SelectMenu>

            {/* Dropdown for Tour Location (Filtered by Safari Type) */}
            <SelectMenu
              title="Select Tour Location"
              options={filteredLocations} // Use the filtered locations based on safari type
              selected={tourLocation}
              onSelect={item => setTourLocation(item.value)} // Save the country name as value
            >
              <Button
                className='input-custom'
                width="100%"
                style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc' }}
              >
                {tourLocation}
              </Button>
            </SelectMenu>

            {/* Dropdown for Available Tour Packages (Filtered by Safari Type and Location) */}
            <SelectMenu
              title="Select Available Tour Package"
              options={filteredTourPackages} // Use the filtered tour packages
              selected={tourPackage}
              onSelect={item => setTourPackage(item.label)}
            >
              <Button
                className='input-custom'
                width="100%"
                style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc' }}
              >
                {tourPackage}
              </Button>
            </SelectMenu>

            <Button
              className='customBtn'
              width="20%"
              onClick={handleSearch}
              style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc' }}
            >
              <CiSearch className='icon-oth' />Search
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
