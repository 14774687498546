import React, { useState, useEffect, useMemo } from 'react';
import { Tablist, Tab, Pane, Heading, TextInputField, Textarea, FilePicker, Button, SelectMenu, TagInput,Spinner, toaster } from 'evergreen-ui';
import './AdminTour.css';

function AdminTour() {
  const initialFormData = useMemo(() => ({
    country: '',
    safari_type: '',
    destination_title: '',
    destination_name: '',
    duration: '',
    price: '',
    image_preview: null,
    info_overview: '',
    info_highlight: '',
    departure: '',
    included: [],
    excluded: [],
    travel_plan_overview: '',
    travel_plan_day_title_1: '',
    travel_plan_day_description_1: '',
    travel_plan_day_title_2: '',
    travel_plan_day_description_2: '',
    travel_plan_day_title_3: '',
    travel_plan_day_description_3: '',
    travel_plan_day_title_4: '',
    travel_plan_day_description_4: '',
    travel_plan_day_title_5: '',
    travel_plan_day_description_5: '',
    travel_plan_day_title_6: '',
    travel_plan_day_description_6: '',
    travel_plan_day_title_7: '',
    travel_plan_day_description_7: '',
    image_gallery_1: null,
    image_gallery_2: null,
    image_gallery_3: null,
    image_gallery_4: null,
    image_gallery_5: null,
    image_gallery_6: null,
    image_gallery_7: null,
    image_gallery_8: null,
    image_gallery_9: null,
  }), []);

  const [formData, setFormData] = useState(initialFormData);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSafariType, setSelectedSafariType] = useState(null); // New state for safari_type
  const [isSubmitting, setIsSubmitting] = useState(false); // State for spinner

  const tabs = ['Basic Info', 'Detailed Info', 'Image Gallery'];

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (files, field) => {
    if (files && files.length > 0) {
      const file = files[0];

      // Check if the file size exceeds 1MB (1MB = 1 * 1024 * 1024 bytes)
      if (file.size > 1 * 1024 * 1024) {
        toaster.danger('File size exceeds 1MB. Please upload a smaller image.');
        return;
      }

      setFormData((prevState) => ({
        ...prevState,
        [field]: file
      }));
    }
  };

  const handleTagInputChange = (field, values) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: values
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show spinner
    toaster.notify('Uploading tour data...');

    const formDataToSend = new FormData();
    for (let key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours`, {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        throw new Error('Error uploading tour data');
      }

      setFormData(initialFormData);
      setSelectedCountry(null);
      setSelectedSafariType(null);
      toaster.success('Tour uploaded successfully!');
    } catch (error) {
      console.error('Error uploading tour data:', error);
      toaster.danger('Failed to upload tour data. Please try again.');
    }finally {
      setIsSubmitting(false); // Hide spinner
    }
  };

  return (
    <div className="upload-tour-container">
      <Heading size={600} marginBottom={16}>Upload New Tour</Heading>
      <Tablist marginBottom={16} flexBasis={240} marginRight={24}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            isSelected={index === selectedIndex}
            onSelect={() => setSelectedIndex(index)}
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      <Pane>
        {selectedIndex === 0 && (
          <Pane padding={16} background="tint1">
            <SelectMenu
              title="Select Country"
              options={['Kenya', 'Uganda', 'Tanzania'].map((label) => ({ label, value: label }))}
              selected={selectedCountry}
              onSelect={(item) => {
                setSelectedCountry(item.value);
                setFormData({ ...formData, country: item.value });
              }}
            >
              <Button style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc', transition: 'background-color 0.3s ease' }}>
                {selectedCountry || 'Select a country (e.g. Kenya)'}
              </Button>
            </SelectMenu>

            <Pane marginBottom={16} />

            <SelectMenu
              title="Select Safari Type"
              options={['Excursions', 'Wildlife Safari', 'Cultural Safari', 'Beach Safari', 'Luxury Safari', 'Family Safari'].map((label) => ({ label, value: label }))}
              selected={selectedSafariType}
              onSelect={(item) => {
                setSelectedSafariType(item.value);
                setFormData({ ...formData, safari_type: item.value });
              }}
            >
              <Button style={{ color: '#000', backgroundColor: '#fff', border: '1px solid #ccc', transition: 'background-color 0.3s ease' }}>
                {selectedSafariType || 'Select a safari type (e.g. Wildlife Safari)'}
              </Button>
            </SelectMenu>

            <Pane marginBottom={16} />

            <TextInputField
              label="Destination Title"
              name="destination_title"
              placeholder="Enter a title (e.g. The Great Wildebeest Migration)"
              value={formData.destination_title}
              onChange={handleChange}
              required
            />
            <TextInputField
              label="Destination Name"
              name="destination_name"
              placeholder="Enter a destination name (e.g. Maasai Mara National Reserve)"
              value={formData.destination_name}
              onChange={handleChange}
              required
            />
            <TextInputField
              label="Duration (in days)"
              name="duration"
              type="number"
              placeholder="Enter number of days (e.g. 7)"
              value={formData.duration}
              fontSize="15px"
              onChange={handleChange}
              required
            />
            <TextInputField
              label="Price (USD)"
              name="price"
              type="number"
              placeholder="Enter the price (e.g. 1500)"
              value={formData.price}
              onChange={handleChange}
              fontSize="15px"
              required
            />
            <FilePicker
              label="Image Preview"
              name="image_preview"
              onChange={files => handleImageChange(files, 'image_preview')}
              required
              accept="image/*"
            />
          </Pane>
        )}
        {selectedIndex === 1 && (
          <Pane padding={16} background="tint1">
            <Textarea
              label="Information Overview"
              name="info_overview"
              placeholder="Enter an overview of the tour (e.g. Brief description of the tour and highlights)"
              value={formData.info_overview}
              onChange={handleChange}
              height={120}
            />
            <Textarea
              label="Tour Highlights"
              name="info_highlight"
              placeholder="Enter key highlights (e.g. Safari drives, wildlife sightings, cultural experiences)"
              value={formData.info_highlight}
              onChange={handleChange}
              height={120}
            />
            <TextInputField
              label="Departure Location"
              name="departure"
              placeholder="Enter the departure location (e.g. Nairobi)"
              value={formData.departure}
              onChange={handleChange}
            />
            <TagInput
              inputProps={{ placeholder: 'Add included items (e.g. Accommodation, meals)' }}
              values={formData.included}
              tagSubmitKey="enter"
              onChange={(values) => handleTagInputChange('included', values)}
              style={{ width: '100%', height: '', marginBottom: '20px'}}
            />

            <TagInput
              inputProps={{ placeholder: 'Add excluded items (e.g. International flights, personal expenses)' }}
              values={formData.excluded}
              tagSubmitKey="enter"
              onChange={(values) => handleTagInputChange('excluded', values)}
              style={{ width: '100%', height: '', marginBottom: '20px'}}
            />
            <Textarea
              label="Travel Plan Overview"
              name="travel_plan_overview"
              placeholder="Enter an overview of the travel plan (e.g. Daily itinerary breakdown)"
              value={formData.travel_plan_overview}
              onChange={handleChange}
              height={120}
            />
            {[1, 2, 3, 4, 5, 6, 7].map((num) => (
              <React.Fragment key={num}>
                <TextInputField
                  label={`Day ${num} Title`}
                  name={`travel_plan_day_title_${num}`}
                  placeholder={`Enter title for Day ${num} (e.g. Arrival and Welcome Briefing)`}
                  value={formData[`travel_plan_day_title_${num}`]}
                  onChange={handleChange}
                />
                <Textarea
                  label={`Day ${num} Description`}
                  name={`travel_plan_day_description_${num}`}
                  placeholder={`Enter details for Day ${num} (e.g. Arrival at Jomo Kenyatta Airport, transfer to hotel)`}
                  value={formData[`travel_plan_day_description_${num}`]}
                  onChange={handleChange}
                  height={120}
                />
              </React.Fragment>
            ))}
          </Pane>
        )}
        {selectedIndex === 2 && (
          <Pane padding={16} background="tint1">
            <Heading size={500} marginBottom={16}>Image Gallery</Heading>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <Pane marginBottom={20} key={num}>
                <FilePicker
                  label={`Upload Image ${num}`}
                  name={`image_gallery_${num}`}
                  onChange={files => handleImageChange(files, `image_gallery_${num}`)}
                  required
                  accept="image/*"
                />
              </Pane>
            ))}
            <Button appearance="primary" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? <Spinner size={16} /> : 'Submit Tour'}
            </Button>
          </Pane>
        )}
      </Pane>
    </div>
  );
}

export default AdminTour;
