import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactUS.css";
import Newsletter from "./Newsletter";

function ContactUS() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Simple validation
    if (!name || !email) {
      setNameError("Name is required");
      setEmailError("Email is required");
      return;
    }

    // Prepare form data for submission
    const formData = { 
      access_key: process.env.REACT_APP_ACCESS_KEY, // Public Access Key
      name: name,
      email: email,
      message: message,
    };

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.success) {
        console.log("Email sent successfully:", result);
        handleReset(); // Clear form fields
      } else {
        console.error("Failed to send email:", result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleReset = () => {
    setName("");
    setEmail("");
    setMessage("");
    setNameError("");
    setEmailError("");
  };

  return (
    <div className="contact-container">
      <div className="banner-dest">
        <div className="banner-sect">
          <p className="banner-title">Contact Us</p>
        </div>
      </div>

      <div data-aos="zoom-in" className="wrapper">
        <div className="form">
          <h4>GET IN TOUCH</h4>
          <h2 className="form-headline">Send us a message</h2>
          <form id="submit-form" onSubmit={handleSubmit}>
            <p>
              <input
                id="name"
                className="form-input"
                type="text"
                placeholder="Your Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <small className="name-error">{nameError}</small>
            </p>
            <p>
              <input
                id="email"
                className="form-input"
                type="email"
                placeholder="Your Email*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <small className="email-error">{emailError}</small>
            </p>
           
            <p className="full-width">
              <textarea
                minLength="20"
                id="message"
                cols="30"
                rows="7"
                placeholder="Your Message*"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </p>

            <p className="full-width">
              <input type="submit" className="submit-btn" value="Submit" />
              <button className="reset-btn" type="button" onClick={handleReset}>
                Reset
              </button>
            </p>
          </form>
        </div>

        <div className="contacts contact-wrapper">
          <ul>
            <li>
            If you have questions or need help with your tour booking, our friendly team is ready to assist. Contact us by phone, email, or via the form below for personalized support in planning your adventure.

            </li>
            <span className="highlight-contact-info">
              <li className="email-info">
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                info@chasylmtours.com
              </li>
              <li>
                <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                <span className="highlight-text">+254793905168</span>
              </li>
            </span>
          </ul>
        </div>
      </div>

      <div data-aos="zoom-in" className="map">
        <iframe
          title="Chasyl HQ"
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d28876.435050822376!2d36.9117918!3d-1.3642102!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x182f0d07e7b8c09b%3A0x8fb76d56e37a9a8a!2sGateway%20Mall%20JWP6%2B8P7%20Airport%20Road%2BMombasa%20Road%20Nairobi!3m2!1d-1.3642102!2d36.911791799999996!5e1!3m2!1sen!2ske!4v1725529512867!5m2!1sen!2ske"
          width="100%"
          height="600"
          border="0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Newsletter />
    </div>
  );
}

export default ContactUS;
