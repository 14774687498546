import React, { useState } from 'react';

function BookingForm() {
  const [formData, setFormData] = useState({
    amount: "",
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/pesapal/submit-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Send the form data to the backend
      });

      const data = await response.json();
      console.log('Order submitted:', data);
      
        // Check if the response contains a redirect_url and redirect the user
        if (data.redirect_url) {
          window.location.href = data.redirect_url; // Redirect to the provided URL
        } else {
          console.error('Redirect URL not found in response:', data);
        }
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '10rem' }}>
      <input type="number" name="amount" value={formData.amount} onChange={handleChange} placeholder="Amount" />
      <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
      <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" />
      <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" />
      <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" />
      <button type="submit">Submit Order</button>
    </form>
  );
}

export default BookingForm;
