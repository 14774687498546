import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../assets/Chasylmlogo.png';
import { VscAccount } from "react-icons/vsc";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobile menu toggle
  const [dropdownOpen, setDropdownOpen] = useState(null); // Track open dropdown

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setDropdownOpen(null); // Close dropdown when menu toggled
  };

  const toggleDropdown = (menu) => {
    setDropdownOpen(dropdownOpen === menu ? null : menu); // Toggle the specific dropdown
  };

  const closeDropdowns = () => {
    setDropdownOpen(null); // Close all dropdowns
  };

  return (
    <div className="nav-container">
      <div className="logo">
        <Link className="li-links" to="/home" onClick={closeDropdowns}>
          <img className="img_logo" style={{ height: '12vh' }} src={logo} alt="Logo" />
        </Link>
      </div>

      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <ul className="ul-links">
          <Link className="li-links" to="/home" onClick={closeDropdowns}><li>Home</li></Link>
          
          {/* Dropdown 1 */}
          <li className="drop-menu">
            <span
              onClick={() => toggleDropdown('pages')}
              className={dropdownOpen === 'pages' ? 'active' : ''}
              style={{fontSize:'17px', fontWeight:'600'}}
            >
              Pages <MdKeyboardArrowDown/>
            </span>
            <ul className={`dropdown ${dropdownOpen === 'pages' ? 'show' : ''}`}>
              <Link className="li-links" to="/about-us" onClick={closeDropdowns}><li>About Us</li></Link>
              <Link className="li-links" to="/what-we-offer" onClick={closeDropdowns}><li>What We Offer</li></Link>
              <Link className="li-links" to="/contact-us" onClick={closeDropdowns}><li>Contact Us</li></Link>
              <Link className="li-links" to="/faq" onClick={closeDropdowns}><li>FAQ Page</li></Link>
            </ul>
          </li>

          <Link className="li-links" to="/destinations" onClick={closeDropdowns}><li>Destinations</li></Link>
          <Link className="li-links" to="/tours" onClick={closeDropdowns}><li>Tour Packages</li></Link>
          <Link className="li-links" to="/blogs" onClick={closeDropdowns}><li>Blog</li></Link>

          {/* Dropdown 2 */}
          <li className="drop-menu">
            <span
              onClick={() => toggleDropdown('account')}
              className={dropdownOpen === 'account' ? 'active' : ''}
            >
              <VscAccount className="nav-icon" /> 
            </span>
            <ul className={`dropdown ${dropdownOpen === 'account' ? 'show' : ''}`}>
              <Link className="li-links" to="/login" onClick={closeDropdowns}><li>Login</li></Link>
              <Link className="li-links" to="/register" onClick={closeDropdowns}><li>Sign up</li></Link>
            </ul>
          </li>
        </ul>
      </div>

      <div>
        <ul>
          <li className="hamburger-menu" onClick={toggleMenu}>
            {isMenuOpen ? <AiOutlineClose className="nav-icon" /> : <GiHamburgerMenu className="nav-icon" />}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
