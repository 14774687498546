import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Register from './components/Register';
import Login from './components/Login';
import FAQ from './components/FAQ';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
// import OurTeam from './components/Team';
import Destination from './components/Destinations';
import Tours from './components/Tours';
import Blogs from './components/Blogs'
import Offer from './components/WhatWeOffer'
import DestinationInfo from './components/Destination_info';
import TourPackages from './components/TourPackage';
import AdminDestination from './components/admin/AdminDestination';
import AdminTour from './components/admin/AdminTour';
import AdminMenu from './components/admin/AdminMenu';
import PageNotFound from './components/PageNotFound'
import UserDashboard from './components/client/UserDashboard'
import TourEdit from './components/admin/TourEdit';
import DestinationEdit from './components/admin/DestinationEdit'
import UserEdit from './components/admin/UserEdit'
import BookingForm from './components/BookingForm'
import BookedTickets from './components/client/BookedTickets'
import PaymentResponsePage from './components/PaymentResponsePage'
import Receipts from './components/admin/Receipts'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'


function App() {
  return (
    <div className="App">
   
   <BrowserRouter>
      <Navbar/>   
      <Routes>
        <Route index element={<Home/>}></Route>
        <Route exact path='/home' element={<Home/>}></Route>
        <Route exact path='/register' element={<Register/>}></Route>
        <Route exact path='/what-we-offer' element={<Offer/>}></Route>
        <Route exact path='/login' element={<Login/>}></Route>
        <Route exact path='/faq' element={<FAQ/>}></Route>
        <Route exact path='/contact-us' element={<ContactUs/>}></Route>
        <Route exact path='/about-us' element={<AboutUs/>}></Route>
        {/* <Route exact path='/Our team' element={<OurTeam/>}></Route> */}
        <Route exact path='/destinations' element={<Destination/>}></Route>
        <Route exact path='/tours' element={<Tours/>}></Route>
        <Route exact path='/blogs' element={<Blogs/>}></Route>
        <Route exact path='/tour-packages' element={<TourPackages/>}></Route>
        <Route path="/destination-info" element={<DestinationInfo />}></Route>         
        <Route exact path='/admin-destination' element={<AdminDestination/>}></Route>
        <Route exact path='/admin-tour' element={<AdminTour/>}></Route>
        <Route exact path='/dashboard-admin' element={<AdminMenu/>}></Route>
        <Route exact path='/error404' element={<PageNotFound/>}></Route>
        <Route exact path='/dashboard-user' element={<UserDashboard/>}></Route>
        <Route exact path='/tour-edit' element={<TourEdit/>}></Route>
        <Route exact path='/destination-edit' element={<DestinationEdit/>}></Route>
        <Route exact path='/user-edit' element={<UserEdit/>}></Route>
        <Route exact path='/bookingform' element={<BookingForm/>}></Route>
        <Route exact path='/BookedTickets' element={<BookedTickets/>}></Route>
        <Route exact path='/response-page' element={<PaymentResponsePage/>}></Route>
        <Route exact path='/booked-receipts' element={<Receipts/>}></Route>
        <Route exact path='/forgot-password' element={<ForgotPassword/>}></Route>
        <Route exact path='/reset-password/:token' element={<ResetPassword/>}></Route>
        
        {/* <Route exact path='/products' element={<Products/>}></Route>
        <Route exact path='/blog' element={<Blog/>}></Route>
        
         */}
        
      </Routes>
      <Footer/>
    </BrowserRouter>

    </div>
  );
}

export default App;
