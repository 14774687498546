import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './Tours.css';
import { SlLocationPin } from "react-icons/sl";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Pagination, Combobox } from 'evergreen-ui';
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from './Newsletter';

function Tours() {
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const toursPerPage = 8; // Number of tours per page

  const navigate = useNavigate();

  // Correctly destructure useSearchParams
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    async function fetchTours() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tours`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTours(data);
      } catch (error) {
        console.error('Error fetching tours:', error);
      }
    }

    fetchTours();
  }, []);

  const handleCardClick = (tour) => {
    // Correct usage of setSearchParams
    setSearchParams({ id: tour.id });
    navigate(`/tour-packages?id=${tour.id}`);
  };

  const filteredTours = selectedCountry === "All Countries"
    ? tours
    : tours.filter(tour => tour.country === selectedCountry);

  // Get current tours
  const indexOfLastTour = currentPage * toursPerPage;
  const indexOfFirstTour = indexOfLastTour - toursPerPage;
  const currentTours = filteredTours.slice(indexOfFirstTour, indexOfLastTour);

  const totalPages = Math.ceil(filteredTours.length / toursPerPage);

  return (
    <div className="tour-container">
      <div className="tour-sect">
        <p className="banner-title">Tour List</p>
      </div>

      <div data-aos="zoom-in-up" className="tour-titles">
        <p className='title-primary'>Choose Your Package</p>
        <p className='title-secondary'>Select Your Best Package For Your Travel</p>
      </div>

      <div data-aos="zoom-in" className="filter_tour_list">
        <Combobox
          openOnFocus
          width="100%"
          height={40}
          items={['All Countries', 'Kenya', 'Uganda', 'Tanzania']}
          onChange={selected => setSelectedCountry(selected)}
          placeholder="Select Country"
          initialSelectedItem="All Countries"
        />
      </div>

      <div data-aos="zoom-in" className="tour-section">
        <div className="card-container">
          {currentTours.map(tour => (
            <div key={tour.id} className="card" onClick={() => handleCardClick(tour)}>
              <div className="card__image_container">
                <img src={`${process.env.REACT_APP_BASE_URL}/${tour.image_preview}`} alt={tour.destination_name} className="card__image" />
              </div>
              <div className="card__info">
                <div className="card-row">
                  <p className="card__text">${tour.price}<span className='per'>/Per Person</span></p>
                  <p className="card__detail days">
                    <FaRegCalendarAlt className='icon__tour' />
                    {tour.duration} Days/ {tour.duration - 1} Nights
                  </p>
                </div>

                <div className="card__title" style={{lineHeight:"25px"}}>                  
                  <span>{tour.destination_title}</span> <br />
                  <span style={{fontSize:'18px'}}><SlLocationPin className='icon__tour' />{tour.destination_name}</span>                   
                </div>


                {/* <p style={{letterSpacing:'0.1px', textAlign:'start', fontSize:'0.9em'}}>{tour.description}</p> */}
                <div className="card__details">
                  <p><span className="star">★</span> 8K+ rating</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Pagination Component */}
      <div className="pagination-container">
        <Pagination
          page={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      <Newsletter />
    </div>
  );
}

export default Tours;
