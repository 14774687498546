import React, {useEffect}from 'react'
import './Section.css'
import AOS from "aos";
import "aos/dist/aos.css";

import { MdOutlineLocalLibrary } from "react-icons/md";
import { FaRecycle } from "react-icons/fa";
import { MdDiversity2 } from "react-icons/md";
import { GiTakeMyMoney } from "react-icons/gi";

function Section() {
  useEffect(() => {
    // window.scrollTo(0,0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div data-aos="zoom-in" className="section_container">
      <div className="sect-title">
        <p className='title-primary'>Why Chasylm Tours & Safaris</p>
        <p className='title-secondary'>Why choose to travel with Chasylm Tours & Safaris </p>
      </div>
    
    <div className='sect-banner'>
    <div className="sect-card">      
    <div className="prof-bg">
      <span> <MdOutlineLocalLibrary class="profileImage"/></span>
      </div>      

    <div class="textContainer">
      <span class="name">Local Expertise</span>
      <span class="profile">With deep knowledge of East Africa's landscapes, wildlife, and cultures, our local guides provide insightful and enriching experiences.</span>
    </div>
    </div>

    <div className="sect-card">
    <div className="prof-bg">
      <span> <FaRecycle class="profileImage"/></span>
      </div>
    <div class="textContainer">
      <span class="name">Sustainable Tourism</span>
      <span class="profile">We prioritize eco-friendly and responsible tourism practices, supporting conservation efforts and local communities in every destination we visit.</span>
    </div>
    </div>

    <div className="sect-card">
      <div className="prof-bg">
      <span> <MdDiversity2 class="profileImage"/></span>
      </div>
   

    <div class="textContainer">
      <span class="name">Diverse Experiences</span>
      <span class="profile">From wildlife safaris and cultural tours to adventure sports and beach getaways, we offer a diverse range of experiences to suit all types of travelers.</span>
    </div>
    </div>

    <div className="sect-card">
    <div className="prof-bg">
      <span> <GiTakeMyMoney class="profileImage"/></span>
      </div>

    <div class="textContainer">
      <span class="name">Unmatched Value for Money</span>
      <span class="profile">We provide high-quality experiences at competitive prices, offering great value without compromising on the quality of service and experiences.</span>
    </div>
    </div>

    </div>

    </div>
  )
}

export default Section