import React, {useEffect} from 'react'

import { GrUserWorker } from "react-icons/gr";
import { FiPackage } from "react-icons/fi";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineCastForEducation } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";
import { FaCampground } from "react-icons/fa";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { MdOutlineVolunteerActivism } from "react-icons/md";

import "./WhatWeOffer.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from './Newsletter';

function BannerDest() {
  useEffect(() => {
    window.scrollTo(0,0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="banner-dest">
      <div className="banner-sect">
        <p className='banner-title'>What we offer</p>
      </div>    
    </div>   
  );
}

function OfferContent({ icon, title, description }) {
  return (
    <div className="offer-content">
      <div className="offer_card">
        <div className="icon-wrapper">{icon}</div>
        <p className="offter-title">{title}</p>
        <p className="offer_cont">{description}</p>
      </div>
    </div>
  );
}

function WhatWeOffer() {
  return (
    <div className="offer-section">    
    <BannerDest />
    <div data-aos="zoom-in" className="offer_row">
      
      <OfferContent
        icon={<GrUserWorker />}
        title="Guided Tours"
        description="Expert-guided safari and wildlife tours, including game drives and walking safaris."
      />
      <OfferContent
        icon={<FiPackage />}
        title="Custom Travel Packages"
        description="Tailor-made itineraries based on clients' preferences and interests."
      />
      <OfferContent
        icon={<FaPeopleGroup />}
        title="Cultural Experiences"
        description="Tours that include visits to local communities and cultural heritage sites."
      />
      <OfferContent
        icon={<MdOutlineCastForEducation />}
        title="Wildlife Conservation Education"
        description="Providing tours that educate about conservation and environmental protection."
      />
      <OfferContent
        icon={<AiOutlineTeam />}
        title="Corporate Team Building "
        description="Customized outdoor activities for corporate groups, including team-building events in natural settings."
      />
      <OfferContent
        icon={<FaCameraRetro />}
        title="Wildlife Photography Tours"
        description="Specialized tours for photographers focusing on the best locations for capturing wildlife."
      />
      <OfferContent
        icon={<FaCampground />}
        title="Camping Safaris"
        description="Outdoor camping experiences in the wild, with basic or semi-luxury campsites."
      />
      <OfferContent
        icon={<MdOutlineFamilyRestroom />}
        title="Family Safaris"
        description="Kid-friendly tours with family-centered activities and accommodations."
      />
      <OfferContent
        icon={<MdOutlineVolunteerActivism />}
        title="Volunteer Programs"
        description="Offering guests opportunities to engage in wildlife conservation or community development projects."
      />
    </div>
    <Newsletter/>
    </div>
  );
}

export default WhatWeOffer;
