import React from 'react'
import './PageNotFound.css'
import img404 from '../assets/undraw_Page_not_found_re_e9o6.png'

function PageNotFound() {
  return (
    <div className="page_not_found">
        <img src={img404} alt="" />
    </div>
  )
}

export default PageNotFound