import React, {useEffect} from 'react';
import './Blogs.css';

import AOS from "aos";
import "aos/dist/aos.css";

import blog1 from '../assets/img1.jpeg';
import blog2 from '../assets/img2.jpeg';
import blog3 from '../assets/img3.jpeg';
import blog4 from '../assets/img4.jpeg';
import blog5 from '../assets/img5.jpeg';
import blog6 from '../assets/img6.jpeg';
import Newsletter from './Newsletter';

function Blog() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo(0,0);
  }, []);

  return ( 
    <div className="blog-header">
      <div className="banner-blog">
        <div className="banner-sect">
          <p className='banner-title'>BLOGS</p>
        </div>
      </div>

      <div className="blog-container-top" data-aos="zoom-in-up">      

        <div class="card_blog">
          <div class="temporary_text"> 
            <img src={blog1} alt="Safari Adventure" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">Top 5 Safari Destinations in Africa</span>
            <span class="card_blog_subtitle">Explore the best spots for an unforgettable wildlife experience</span>
            <p class="card_blog_description">
              Africa is home to some of the most stunning safari destinations, offering breathtaking landscapes and incredible wildlife encounters. In this blog, we highlight the top five must-visit safari destinations, including Kenya’s Maasai Mara, Tanzania’s Serengeti, and South Africa’s Kruger National Park.
            </p>
          </div>
        </div>   

        <div class="card_blog">
          <div class="temporary_text">
            <img src={blog2} alt="Wildlife Close-Up" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">A Guide to the Big Five: Spotting Wildlife on Safari</span>
            <span class="card_blog_subtitle">How to spot Africa's most iconic animals on your safari</span>
            <p class="card_blog_description">
              The Big Five—lion, leopard, rhino, elephant, and buffalo—are the ultimate highlight of any safari. Learn about the best times and places to spot these magnificent creatures, along with expert tips for getting the most out of your safari experience.
            </p>
          </div>
        </div>

        <div class="card_blog">
          <div class="temporary_text">
            <img src={blog3} alt="Luxury Safari Lodges" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">Luxury Safari Lodges: Where to Stay in the Wilderness</span>
            <span class="card_blog_subtitle">Discover Africa’s most luxurious and eco-friendly lodges</span>
            <p class="card_blog_description">
              Experience Africa’s wilderness without sacrificing comfort. Our guide to the top luxury safari lodges offers insights into eco-friendly stays that provide top-tier amenities, stunning views, and close-up wildlife experiences.
            </p>
          </div>
        </div> 

        <div class="card_blog">
          <div class="temporary_text">
            <img src={blog4} alt="Family Safari" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">Family Safaris: Tips for an Unforgettable Adventure with Kids</span>
            <span class="card_blog_subtitle">How to plan a safari adventure that’s fun and safe for all ages</span>
            <p class="card_blog_description">
              Safaris can be a magical experience for families. From choosing child-friendly lodges to keeping kids engaged with wildlife activities, this blog covers everything you need to plan a safe and exciting safari adventure for your family.
            </p>
          </div>
        </div>

        <div class="card_blog">
          <div class="temporary_text">
            <img src={blog5} alt="Photography Safari" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">Photography Safari: Capturing Africa’s Wildlife Like a Pro</span>
            <span class="card_blog_subtitle">Expert tips for capturing stunning safari moments on camera</span>
            <p class="card_blog_description">
              Whether you're an amateur or professional photographer, capturing the beauty of Africa’s wildlife requires the right gear and techniques. In this blog, we share essential tips to help you take breathtaking wildlife photos during your safari.
            </p>
          </div>
        </div>

        <div class="card_blog">
          <div class="temporary_text">
            <img src={blog6} alt="Cultural Tours" />
          </div>
          <div class="card_blog_content">
            <span class="card_blog_title">Cultural Tours: Exploring Africa Beyond the Wildlife</span>
            <span class="card_blog_subtitle">Discover the rich cultures and traditions of Africa’s local communities</span>
            <p class="card_blog_description">
              Africa’s diverse cultures are as fascinating as its wildlife. Join us on a journey through the vibrant communities, rich traditions, and incredible stories of the people who call this continent home, from the Maasai tribes to the Zulu Kingdom.
            </p>
          </div>   
        </div>         
      </div> 
      <Newsletter/> 
    </div>
  );
}

export default Blog;
